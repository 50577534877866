import React from "react"
import styled from "styled-components"
import { breakpoints } from "../theme"
import { title2_Emphasis } from "./global-styles"
import { GatsbyImage } from "gatsby-plugin-image";
import "./rounded-grid-item.scss"
const Wrapper = styled.div`
  margin-bottom: 64px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 0;
  }
`
const DivImage = styled.div`
  height: 225px;
  border-radius: 50%;
`
const Title = styled.h3`
  ${title2_Emphasis};
  margin-bottom: 18px;
`
const ListItem = styled.p`
  margin-bottom: 5px;
`

const RoundedGridItem = ({ image, title, items = [], ...rest }) => {
  return (
    <Wrapper className="d-flex flex-column align-items-start" {...rest}>
      <GatsbyImage
      objectFit="contain"
        className="image-rounded"
        image={image}
        alt={title} />
      <Title>{title}</Title>
      <div>
        {items.map((item, i) => (
          <ListItem className="body1" key={i.toString()}>{item}</ListItem>
        ))}
      </div>
    </Wrapper>
  )
}

export default RoundedGridItem
