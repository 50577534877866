import React from "react"
import styled, { useTheme } from "styled-components"
import IntlText from "../../components/intl-text"

import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout"
import PageHeader from "../../components/page-header"
import PageWrapper from "../../components/page-wrapper"
import RoundedGridItem from "../../components/rounded-grid-item"
import SquaredGridItem from "../../components/squared-grid-item"
import { breakpoints } from "../../theme"
import FreeTrialBlock from "../../components/free-trial-block"
import SectionTitle from "../../components/section-title"
import ProcessBlock from "../../components/process-block"
import { getImage } from "gatsby-plugin-image";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.medium}) {
  }
`

const technologies = [
  {
    title: "Front End Engineer",
    imageDark: "staff-augmentation/dark/frontend.png",
    image: "staff-augmentation/light/frontend.png",
    items: [
      "JavaScript (React.js)",
      "HTML5, CSS3",
      "UI/UX best practices",
      "5+ years of experience"
    ],
  },
  {
    title: "Fullstack Engineer",
    imageDark: "staff-augmentation/dark/fullstack.png",
    image: "staff-augmentation/light/fullstack.png",
    items: [
      "JavaScript (Node.js & React.js)",
      "Database design",
      "SQL and NoSQL databases",
      "5+ years of experience"
    ],
  },
  {
    title: "Mobile Engineer",
    imageDark: "staff-augmentation/dark/mobile.png",
    image: "staff-augmentation/light/mobile.png",
    items: [
      "JavaScript (React Native)",
      "Expo",
      "UI/UX best practices",
      "5+ years of experience"
    ],
  },
  {
    title: "Product Designer",
    imageDark: "staff-augmentation/dark/designer.png",
    image: "staff-augmentation/light/designer.png",
    items: [
      "UI/UX best practices",
      "Design Thinking",
      "InVision, Figma",
      "5+ years of experience"
    ],
  },
]

const quality = [
  {
    title: "Pre-vetted Engineers",
    className:"image-squared-grid-one",
    imageDark: "staff-augmentation/dark/quality-engineers.png",
    image: "staff-augmentation/light/quality-engineers.png",
    body:
      "Product engineers with solid skills, several years of experience and multiple products developed. People who know what they are doing and why they are doing it.",
  },
  {
    title: "Consulting included",
    className:"image-squared-grid-one",
    imageDark: "staff-augmentation/dark/quality-consulting.png",
    image: "staff-augmentation/light/quality-consulting.png",
    body:
      "In addition to the engineers you have hired us, you can consult other professionals on our team at no additional cost to help you solve other types of problems.",
  },
  {
    title: "Dedicated Product owner",
    className:"image-squared-grid-two",
    imageDark: "staff-augmentation/dark/quality-product-owner.png",
    image: "staff-augmentation/light/quality-product-owner.png",
    body:
      "For things to go well, an Engineer alone is not enough. A Product Owner from our team will follow up on your projects to make sure everything goes as expected.",
  },
]

const flexibility = [
  {
    title: "Scale up & down",
    className:"image-squared-grid-one",
    imageDark: "staff-augmentation/dark/flexibility-scale.png",
    image: "staff-augmentation/light/flexibility-scale.png",
    body:
      "Projects are dynamic, needs change and your team must be able to change as well. Increase or reduce the number of professionals hired whenever you want.",
  },
  {
    title: "Cross-Tech",
    className:"image-squared-grid-three",
    imageDark: "staff-augmentation/dark/flexibility-cross-tech.png",
    image: "staff-augmentation/light/flexibility-cross-tech.png",
    body:
      "When working with Javascript, many of our professionals know how to solve in Frontend Web, Backend and even Mobile Apps. One onboarding, multiple solutions.",
  },
  {
    title: "English + Spanish",
    className:"image-squared-grid-three",
    imageDark: "staff-augmentation/dark/flexibility-language.png",
    image: "staff-augmentation/light/flexibility-language.png",
    body:
      "You can speak in English or Spanish with our team, as you prefer. Many of us are from Argentina, which is the country with the highest English proficiency in Latin America.",
  },
]

const savings = [
  {
    title: "Recruiting & training",
    className:"image-squared-grid-four",
    imageDark: "staff-augmentation/dark/savings-training.png",
    image: "staff-augmentation/light/savings-training.png",
    body:
      "Choosing the right people for a position takes a long time, costs a lot of money, and is a huge headache. Take advantage, we have already done that job for you.",
  },
  {
    title: "Vacation & holidays",
    className:"image-squared-grid-four",
    imageDark: "staff-augmentation/dark/savings-holidays.png",
    image: "staff-augmentation/light/savings-holidays.png",
    body:
      "Taking time off is essential for our health and well-being. However, it is also a cost for businesses. You will not have to pay for it. We take care of those costs.",
  },
  {
    title: "Sick & extra days off",
    className:"image-squared-grid-four",
    imageDark: "staff-augmentation/dark/savings-day-off.png",
    image: "staff-augmentation/light/savings-day-off.png",
    body:
      "Either because the professional is not feeling well or needs time to resolve personal issues, that time will not be part of your monthly bill. Yes, we take care of that too.",
  },
]

const StaffAugmentation = () => {
  const { isDark } = useTheme();
  const data = useStaticQuery(graphql`
  query imageStaffAugmentation {
    allFile(filter: {relativePath: { regex: "/staff-augmentation/"}}){
      edges {
        node {
          id
          base
          relativePath
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH,
                formats: [WEBP]
              )
            }
        }
      }
    }
  }
`);

  return (
    <Layout
      pageTitle="Services"
      title="A software team augmentation to scale your work - Sysgarage"
      description="We offer a software team augmentation for your business to growth, speed or scale your web or app development. Contact Us!"
    >
      <PageWrapper>
        <PageHeader
          title={<IntlText id="pages.staff_augmentation.title" />}
          body={<IntlText type="h2" id="pages.staff_augmentation.subtitle" />}
        />
        <Content>
          <div
            className="row technologies section-container"
            style={{ marginTop: "64px" }}
          >
            {technologies.map((t, i) => (
              <div className="col-md-3" key={i}>
                <RoundedGridItem
                  image={getImage(data.allFile.edges.filter((i) => i.node.relativePath.includes(isDark ? t.imageDark : t.image))[0].node.childImageSharp)}
                  title={t.title}
                  items={t.items}
                />
              </div>
            ))}
          </div>

          <div className="quality section-container">
            <SectionTitle>Quality</SectionTitle>
            <div className="row">
              {quality.map((q, i) => (
                <div className="col-md-4" key={i}>
                  <SquaredGridItem
                    className={q.className}
                    image={getImage(data.allFile.edges.filter((j) => j.node.relativePath.includes(isDark ? q.imageDark : q.image))[0].node.childImageSharp)}
                    title={q.title}
                    body={q.body}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="flexibility section-container">
            <SectionTitle>Flexibility</SectionTitle>
            <div className="row">
              {flexibility.map((f, i) => (
                <div className="col-md-4" key={i}>
                  <SquaredGridItem
                    className={f.className}
                    image={getImage(data.allFile.edges.filter((j) => j.node.relativePath.includes(isDark ? f.imageDark : f.image))[0].node.childImageSharp)}
                    title={f.title}
                    body={f.body}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="savings section-container">
            <SectionTitle>Savings</SectionTitle>
            <div className="row">
              {savings.map((s, i) => (
                <div className="col-md-4" key={i}>
                  <SquaredGridItem
                    className={s.className}
                    image={getImage(data.allFile.edges.filter((j) => j.node.relativePath.includes(isDark ? s.imageDark : s.image))[0].node.childImageSharp)}
                    title={s.title}
                    body={s.body}
                  />
                </div>
              ))}
            </div>
          </div>
        </Content>

        <div className="free-trial section-container">
          <FreeTrialBlock />
        </div>

        <div className="last section-container">
          <ProcessBlock />
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default StaffAugmentation
